.optionsBox {
  display: flex;
  flex-direction: row;
  text-align: center;
}

.optionsBtn {
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  font-size: 26px;
  padding-bottom: 10px;
  padding-right: 15px;
  /* background-color: red; */
  border-radius: 24px;
  padding-right: 5px;
}

.optionsBtn:focus {
  color: gray;
}

.openedBtn {
  display: none;

  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  font-size: 15px;
  padding-right: 15px;
  /* background-color: red; */
  border-radius: 24px;
  padding-right: 5px;
}

.openedBtn:focus {
  color: gray;
}

.opendButtons {
  display: none;
}

.info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* margin-right: 260px; */
}

.workerName {
  margin-left: 40px;
  font-size: 18px;
  font-weight: bold;
}

.workerRoute {
  font-size: 18px;
  font-weight: bold;
}
