.App {
  text-align: center;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-link {
  color: #61dafb;
}

@import url("https://fonts.cdnfonts.com/css/roblox-new");

.table {
  position: relative;
  height: 800px;
  width: 1500px;
  color: black;
  font-family: "Gotham Black", sans-serif;
}

.headline {
  font-size: 4rem;
  color: black;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Gotham Black", sans-serif;
}

.alternativeBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-top: -41px;
  justify-content: center;
}

.switch-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: black;
  height: 4vh;
  border-radius: 4px;
  font-size: 16px;
  position: absolute;
  left: 0;
  margin-left: 20px;
  margin-top: 20px;
}

.switch-button.hebrew {
  /* box-shadow: 2.5px 1.5px 1.5px 2.5px blue; */
  border-color: blue;
  border-style: solid;

  /* revert style */
}

.switch-button.english {
  /* box-shadow: 2.5px 1.5px 1.5px 2.5px red; */
  border-color: red;
  border-style: solid;
}

.Navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 10px;
  background-color: lightgray;
}

.btn_nav {
  background: none;
  border: none;
  cursor: pointer;
}
