.status {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* direction: rtl; */
  /* justify-content: center; */
  /* height: 30px;
  width: 110px; */
  /* align-items: center;
  justify-content: center;
  /* border-radius: 25px; */
}

.textStatus {
  position: relative;
  /* top: 0.5px; */
  display: table-cell;
  vertical-align: middle;
  right: 10px;
  font-family: "Gotham Black", sans-serif;
  font-size: medium;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  margin-right: 15px;
}
